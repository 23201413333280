import React, { useCallback, useEffect, useState } from "react";
import styles from "pages/team_settings/style.module.scss";
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
  CollectionPreferences,
  Header,
  Link,
  Pagination,
  Table,
} from "@amzn/awsui-components-react";
import {
  SpaceBetween,
  PropertyFilter,
  PropertyFilterProps,
} from "@amzn/awsui-components-react/polaris";
import Button from "@amzn/awsui-components-react/polaris/button";
import { useAppDispatch } from "app/store";
import {
  useCreateTaxAreaMutation,
  useGetTaxAreaByIdQuery,
  useListTaxAreaByTeamQuery,
  useUpdateTaxAreaMutation,
} from "pages/team_settings/endpoints";
import { ListTaxAreaRequest } from "pages/team_settings/definitions";
import {
  TaxArea,
  CreateTaxAreaFormFields,
  UpdateTaxAreaFormFields,
} from "models/schemas";
import EmptyState from "components/empty-state";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useColumnWidths } from "hooks";
import { CreateTaxAreaModal } from "components/create-taxarea-modal";
import { useNotifications } from "features/notifications";
import { ITaxAreaTableProps } from "./definitions";
import {
  CollectionPreferencesProps,
  TableProps,
} from "@amzn/awsui-components-react";
import TableItemStatus from "components/table-item-status";
import { EditTaxAreaModal } from "components/edit-taxarea-modal/EditTaxAreaModal";
import { toDateString } from "components/create-audit-form/utils";
import {
  commonPropertyFilterI18nStrings,
  createLabelFunction,
  getMatchesCountText,
} from "utils/table-utils";
import { GaiaConstants } from "utils/gaia-constants";

const TaxAreaTable: React.FC<ITaxAreaTableProps> = (props) => {
  const dispatch = useAppDispatch();
  const storage = localStorage.getItem(GaiaConstants.SELECTED_TEAM_NAME);
  const [teamName] = useState(storage ? storage.valueOf() : "");

  const [taxAreaData, setTaxAreaData] = useState<TaxArea[]>([]);
  const listTaxAreaRequest: ListTaxAreaRequest = {
    teamName: teamName,
  };
  const { data } = useListTaxAreaByTeamQuery(listTaxAreaRequest, {
    refetchOnMountOrArgChange: true,
    skip: !teamName,
  });

  useEffect(() => {
    if (data?.taxAreas) {
      setTaxAreaData(
        data?.taxAreas.taxAreas.map((e) => {
          return { ...e };
        })
      );
    }
  }, [data?.taxAreas]);

  const [showCreateTaxAreaModal, setShowCreateTaxAreaModal] = useState(false);
  const handleCreateTaxArea = () => {
    setShowCreateTaxAreaModal(true);
  };

  const pageSizePreference = {
    title: "Page size",
    options: [
      { value: 25, label: "25 tax areas" },
      { value: 50, label: "50 tax areas" },
    ],
  };

  const formatDate = (date: String) => {
    return date.replaceAll("-", "/");
  };

  const getLastUpdated = (item: any) => {
    return `${formatDate(toDateString(item.modifiedAt))} by ${item.modifiedBy}`;
  };

  const rawColumnDefinitions: TableProps<any>["columnDefinitions"] = [
    {
      id: "title",
      header: "Title",
      cell: (item) => goToTaxAreaEditPage(item),
      ariaLabel: createLabelFunction("Title"),
      sortingField: "title",
    },
    {
      id: "category",
      header: "Category",
      cell: (item) => item.category,
      ariaLabel: createLabelFunction("Category"),
      sortingField: "category",
    },
    {
      id: "status",
      header: "Status",
      cell: (item) => <TableItemStatus stringStatus={item.status} />,
      ariaLabel: createLabelFunction("Status"),
      sortingField: "status",
    },
    {
      id: "description",
      header: "Description",
      cell: (item) => item.description,
      ariaLabel: createLabelFunction("Description"),
      sortingField: "description",
    },
    {
      id: "createdBy",
      header: "Created by",
      cell: (item) => item.createdBy,
      ariaLabel: createLabelFunction("Created by"),
      sortingField: "createdBy",
    },
    {
      id: "createdAt",
      header: "Date created",
      cell: (item) => formatDate(toDateString(item.createdAt)),
      ariaLabel: createLabelFunction("Date created"),
      sortingField: "createdAt",
    },
    {
      id: "lastUpdated",
      header: "Last updated",
      cell: (item) => getLastUpdated(item),
      ariaLabel: createLabelFunction("Last updated"),
      sortingField: "lastUpdated",
      sortingComparator: (a, b) =>
        getLastUpdated(a).localeCompare(getLastUpdated(b)),
    },
  ];
  const contentDisplayPreference: CollectionPreferencesProps["contentDisplayPreference"] =
    {
      title: "Select visible content",
      options: rawColumnDefinitions.map(({ id, header }) => ({
        id: id || "",
        label: `${header}`,
        alwaysVisible: id === "title",
      })),
    };

  const contentDisplayPreferenceDefault: {
    id: any;
    label: any;
    visible: boolean;
  }[] = rawColumnDefinitions.map(({ id, header }) => ({
    id: id || "",
    label: `${header}`,
    visible: id !== "createdBy" && id !== "createdAt" && id !== "lastUpdated",
  }));

  const collectionPreferencesProps: CollectionPreferencesProps = {
    pageSizePreference,
    contentDisplayPreference,
    cancelLabel: "Cancel",
    confirmLabel: "Confirm",
    title: "Preferences",
  };

  //CREATE TAX AREA
  const { addNotification } = useNotifications();
  const [createTaxArea] = useCreateTaxAreaMutation();
  const taxAreaTitleAlreadyExists = (title: string) => {
    return taxAreaData.some(
      (taxArea) => taxArea.title.toLowerCase() === title.toLowerCase()
    );
  };
  const [newTaxAreaId, setNewTaxAreaId] = useState(String);
  const onCreate = useCallback(
    async (newTaxArea: CreateTaxAreaFormFields) => {
      try {
        setShowCreateTaxAreaModal(false);
        const createTaxAreaInput = {
          teamName: teamName,
          title: newTaxArea.title,
          category: newTaxArea.category,
          status: newTaxArea.status,
          description: newTaxArea.description ?? "",
        };
        if (taxAreaTitleAlreadyExists(newTaxArea.title)) {
          addNotification({
            type: "error",
            header: "Tax area title must be unique.",
            dismissible: true,
            timeout: 5000,
          });
          setShowCreateTaxAreaModal(false);
          return;
        }
        const response = await createTaxArea(createTaxAreaInput).unwrap();
        setNewTaxAreaId(response.taxAreaId);
        addNotification({
          type: "success",
          header: "New tax area has been added.",
          dismissible: true,
          timeout: 5000,
        });
      } catch (error) {
        addNotification({
          type: "error",
          header: "Failed to add the tax area",
          dismissible: true,
          timeout: 5000,
        });
      }
      setShowCreateTaxAreaModal(false);
    },
    [addNotification, createTaxArea, teamName]
  );
  const { data: getTaxAreaData } = useGetTaxAreaByIdQuery(
    { taxAreaId: newTaxAreaId, teamName: teamName },
    { skip: newTaxAreaId === "" } // Skip the query if newTaxAreaId is null
  );
  useEffect(() => {
    if (getTaxAreaData) {
      setTaxAreaData([...taxAreaData, getTaxAreaData.taxArea]);
      setNewTaxAreaId("");
    }
  }, [getTaxAreaData, dispatch]);
  //UPDATE TAX AREA
  const [selectedTaxArea, setSelectedTaxArea] = useState<TaxArea>();
  const [showEditTaxAreaModal, setShowEditTaxAreaModal] = useState(false);
  const handleEditTaxArea = (title: String) => {
    for (let taxArea of taxAreaData) {
      if (taxArea.title === title) {
        setSelectedTaxArea(taxArea);
      }
    }
    setShowEditTaxAreaModal(true);
  };
  const goToTaxAreaEditPage = (item: any) => {
    return (
      <div>
        <Link
          className="audit-overv"
          onFollow={() => {
            handleEditTaxArea(item.title);
          }}
        >
          {item.title}
        </Link>
      </div>
    );
  };
  const taxAreasAreTheSame = (taxArea1: TaxArea, taxArea2: TaxArea) => {
    return (
      taxArea1.title === taxArea2.title &&
      taxArea1.category === taxArea2.category &&
      taxArea1.status === taxArea2.status &&
      taxArea1.description === taxArea2.description
    );
  };
  const [updateTaxArea] = useUpdateTaxAreaMutation();
  const onEdit = useCallback(
    async (updatedTaxAreaFields: UpdateTaxAreaFormFields) => {
      if (selectedTaxArea === undefined) {
        return;
      }
      const newTaxArea = {
        taxAreaId: selectedTaxArea.taxAreaId,
        teamName: teamName,
        title: updatedTaxAreaFields.title,
        category: updatedTaxAreaFields.category,
        status: updatedTaxAreaFields.status,
        description: updatedTaxAreaFields.description,
      };
      if (taxAreasAreTheSame(selectedTaxArea, newTaxArea)) {
        addNotification({
          type: "info",
          header: "No edits were made to tax area.",
          dismissible: true,
          timeout: 5000,
        });
        console.log("New tax area and old are the same");
        setShowEditTaxAreaModal(false);
        return;
      }
      try {
        setShowEditTaxAreaModal(false);
        const updateTaxAreResponse = await updateTaxArea(newTaxArea).unwrap();
        const updatedTaxArea = updateTaxAreResponse.taxArea;
        addNotification({
          type: "success",
          header: "Changes to tax area are saved.",
          dismissible: true,
          timeout: 5000,
        });
        setTaxAreaData(
          taxAreaData.map((taxArea) => {
            if (taxArea.taxAreaId === updatedTaxArea.taxAreaId) {
              return { ...updatedTaxArea };
            }
            return taxArea;
          })
        );
        setSelectedTaxArea(undefined);
      } catch (error) {
        addNotification({
          type: "error",
          header: "Failed to make changes to tax area.",
          dismissible: true,
          timeout: 5000,
        });
      }
      setShowEditTaxAreaModal(false);
    },
    [selectedTaxArea, updateTaxArea, teamName, addNotification]
  );

  //TABLE SETUP
  const [columnDefinitions, saveWidths] = useColumnWidths(
    teamName + "-TaxAreaList-Widths",
    rawColumnDefinitions
  );
  const [preferences, setPreferences]: any = useLocalStorage(
    teamName + "-TaxAreaList-Preferences",
    {
      pageSize: 50,
      contentDisplay: contentDisplayPreferenceDefault,
    }
  );

  type TaxAreaTableFilteringProperty = PropertyFilterProps.FilteringProperty & {
    key: keyof TaxArea;
  };

  const FILTERING_PROPERTIES: TaxAreaTableFilteringProperty[] = [
    {
      propertyLabel: "Title",
      key: "title",
      groupValuesLabel: "Titles",
      operators: [":", "=", "^"],
    },
    {
      propertyLabel: "Description",
      key: "description",
      groupValuesLabel: "Descriptions",
      operators: [":"],
    },
    {
      propertyLabel: "Category",
      key: "category",
      groupValuesLabel: "Category values",
      operators: [
        { operator: "=", tokenType: "enum" },
        { operator: "!=", tokenType: "enum" },
      ],
    },
    {
      propertyLabel: "Status",
      key: "status",
      groupValuesLabel: "Status values",
      operators: [
        { operator: "=", tokenType: "enum" },
        { operator: "!=", tokenType: "enum" },
      ],
    },
  ];

  const propertyFilterI18nStrings: PropertyFilterProps.I18nStrings = {
    ...commonPropertyFilterI18nStrings,
    filteringPlaceholder: "Find tax areas by title or description",
  };
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(Object.values(taxAreaData), {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: (
        <EmptyState
          title="No tax areas"
          subtitle="No tax areas to display."
          action={
            <Button onClick={() => handleCreateTaxArea()}>
              Create tax area
            </Button>
          }
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We cant find a match."
          action={
            <Button onClick={() => actions.setFiltering("")}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: "title",
        },
      },
    },
  });
  return (
    <div>
      <SpaceBetween size="l">
        <Table
          {...collectionProps}
          header={
            <div style={{ marginBottom: "10px" }}>
              <Header
                counter={
                  filteredItemsCount === taxAreaData.length
                    ? `(${taxAreaData.length})`
                    : `(${filteredItemsCount}/${taxAreaData.length})`
                }
                actions={
                  <SpaceBetween size={"s"} direction="horizontal">
                    <div>
                      <Button variant="primary" onClick={handleCreateTaxArea}>
                        Add
                      </Button>
                      {showCreateTaxAreaModal && (
                        <CreateTaxAreaModal
                          visible={showCreateTaxAreaModal}
                          setVisible={setShowCreateTaxAreaModal}
                          onCreate={onCreate}
                        />
                      )}
                      {showEditTaxAreaModal && (
                        <EditTaxAreaModal
                          visible={showEditTaxAreaModal}
                          setVisible={setShowEditTaxAreaModal}
                          onEdit={onEdit}
                          selectedTaxArea={selectedTaxArea!}
                        />
                      )}
                    </div>
                  </SpaceBetween>
                }
              >
                Tax areas
              </Header>
            </div>
          }
          columnDefinitions={columnDefinitions}
          columnDisplay={preferences.contentDisplay}
          resizableColumns={true}
          onColumnWidthsChange={saveWidths}
          items={items}
          pagination={<Pagination {...paginationProps} />}
          filter={
            <div className={styles.filterBoxWrapper}>
              <div className={styles.textFilter} style={{ width: "500px" }}>
                <PropertyFilter
                  {...propertyFilterProps}
                  i18nStrings={propertyFilterI18nStrings}
                  expandToViewport
                  countText={getMatchesCountText(filteredItemsCount)}
                />
              </div>
            </div>
          }
          preferences={
            <CollectionPreferences
              {...collectionPreferencesProps}
              preferences={preferences}
              onConfirm={({ detail }) => {
                setPreferences(detail);
              }}
            />
          }
          variant="container"
        />
      </SpaceBetween>
    </div>
  );
};

export default TaxAreaTable;
